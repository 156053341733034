import axios from './index';
import {buildPath} from "../../shared/utilities";

export const getTopTracks = function ({range, token, limit = 15}) {
    return axios(token).get(
        buildPath('me', 'top', 'tracks'), {
            params: {
                limit: limit,
                time_range: range
            }
        }
    );
};

export const getTopArtists = function ({range, token, limit = 15}) {
    return axios(token).get(
        buildPath('me', 'top', 'artists'), {
            params: {
                limit: limit,
                time_range: range
            }
        }
    );
};