import {getRandomString, getUrlParam} from "../../shared/utilities";
import {SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URL, SPOTIFY_SCOPE, SPOTIFY_STATE_KEY} from "../../shared/constants";

export function auth() {
    const state = getRandomString(16);
    window.localStorage.setItem(SPOTIFY_STATE_KEY, state);
    window.location = 'https://accounts.spotify.com/authorize'
        + '?response_type=token'
        + '&client_id=' + encodeURIComponent(SPOTIFY_CLIENT_ID)
        + '&scope=' + encodeURIComponent(SPOTIFY_SCOPE)
        + '&redirect_uri=' + encodeURIComponent(SPOTIFY_REDIRECT_URL)
        + '&state=' + encodeURIComponent(state);
}

export function getToken() {
    const state = getUrlParam('state');
    const storedState = window.localStorage.getItem(SPOTIFY_STATE_KEY);
    const token = getUrlParam('access_token');
    window.history.pushState({
        authenticated: true
    }, 'Music Data App', '/');
    if (token && (!state || state !== storedState))
        throw Error('An error occurred during the authentication!');
    else {
        window.localStorage.removeItem(SPOTIFY_STATE_KEY);
        return token;
    }
}

export function signOut(callback) {
    const url = 'https://accounts.spotify.com/en/logout';
    const signOutWindow = window.open(url, 'Spotify Logout', 'width=700,height=500,top=40,left=40');
    setTimeout(() => {signOutWindow.close(); callback()}, 2000);
}