import React, {Component, Fragment} from 'react';
import logo from "../../assets/images/Logo.svg";
import './Layout.css';
import ProfileBox from "../../components/ProfileBox/ProfileBox";
import Navigation from "../../components/Navigation/Navigation";
import NowPlaying from "../../containers/NowPlaying/NowPlaying";
import {connect} from "react-redux";

class Layout extends Component {
    render() {
        return (
            <Fragment>
                <div className="SiteLogo">
                    <img src={logo} alt="Music Data App Logo"/>
                    <span className="sr-only">Music Data App</span>
                </div>
                <ProfileBox
                    user={this.props.user}
                    authenticated={this.props.authenticated}
                    signout={this.props.signout}/>
                <div className="left-column">
                    <Navigation authenticated={this.props.authenticated}/>
                    {this.props.authenticated ? <NowPlaying/> : null}
                </div>
                <main>
                    <div className={"content-wrapper" + (this.props.authenticated ? '' : ' unauthed')}>
                        {this.props.children}
                    </div>
                </main>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);