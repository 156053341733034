import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../shared/utilities";

const initialState = {
    currentlyPlaying: null,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_SUCCESS:
            return updateObject(state, {
                currentlyPlaying: action.currentlyPlaying
            });
        case actionTypes.PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        default:
            return state;
    }
};

export default reducer;