import React, {Component, Fragment} from 'react';
import './Genres.css';
import {
    Card,
    CardBody, CardText, CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
import * as actions from "../../store/actions";
import {DEFAULT_SCROLL_OPTIONS, SPOTIFY_PERSONALIZATION_RANGES} from "../../shared/constants";
import {connect} from "react-redux";
import * as Scroll from 'react-scroll';

class Genres extends Component {
    state = {
        options: SPOTIFY_PERSONALIZATION_RANGES.slice(0),
        genreSelected: null
    };

    componentDidMount() {
        this.props.getTopGenres(this.state.options[0].range);
    }

    changeRangeHandler = (o, i) => {
        this.props.getTopGenres(o.range);
        this.setState(prev => {
            const updatedOptions = prev.options.slice();
            updatedOptions.splice(i + 1, 1);
            updatedOptions.unshift(o);
            return {
                options: updatedOptions,
                genreSelected: null
            }
        })
    };

    changeGenreHandler = (genre, index) => {
        this.setState({
            genreSelected: {...genre, rank: index + 1}
        });
        Scroll.scroller.scrollTo('Genres', DEFAULT_SCROLL_OPTIONS);
    };

    render() {
        let genres = {};
        this.props.topGenres.forEach((t, i, arr) => {
            t.genres.forEach(g => {
                genres[g] = genres[g] || {
                    count: 0,
                    ranked_count: 0,
                    artists: []
                };
                genres[g].count++;
                genres[g].ranked_count += arr.length - i;
                genres[g].artists.push(t.name);
            });
        });
        genres = Object.keys(genres).map(genre => ({
            ...genres[genre],
            name: genre
        })).filter(g => g.count > 1).sort((a, b) => {
            return b.ranked_count - a.ranked_count;
        });
        const genreMapper = (value, index) => (
            <li key={index} className="Genre" style={{
                fontSize: (Math.min(250, Math.max(value.ranked_count, 100))) + '%',
                color: 'hsl(198, ' +
                    Math.round(40 + Math.random() * 50) + '%, ' +
                    Math.round(25 + Math.random() * 50) + '%)'
            }} onClick={() => this.changeGenreHandler(value, index)}>{value.name}</li>
        );
        return (
            <section id="Genres">
                <div className="Genres-Header">
                    <div className="h2 horizontal">Your</div>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret size="lg" color="success">{this.state.options[0].label}</DropdownToggle>
                        <DropdownMenu>
                            {
                                this.state.options.slice(1).map((o, i) => <DropdownItem
                                    key={o.range}
                                    onClick={() => this.changeRangeHandler(o, i)}
                                >{o.label}</DropdownItem>)
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="h2 vertical">Top Genres</div>
                </div>
                <div className="Genres-Body">
                    <Card color="info" className="text-left text-white Genre-Info">
                        <CardBody>
                            <CardTitle className={this.state.genreSelected ? 'mb-2' : 'mb-0'}>
                                {
                                    this.state.genreSelected ?
                                        ('#' + this.state.genreSelected.rank + ' ' + this.state.genreSelected.name) :
                                        'Click on a Genre to see details'
                                }
                            </CardTitle>
                            {
                                this.state.genreSelected ? (
                                    <Fragment>
                                        <CardText className="mb-2"><strong>Ranked Score: </strong>
                                            {this.state.genreSelected.ranked_count}</CardText>
                                        <CardText><strong>Your Top Artist(s) in this Genre: </strong>
                                            {this.state.genreSelected.artists.join(', ')}</CardText>
                                    </Fragment>
                                ) : null
                            }
                        </CardBody>
                    </Card>
                    <ul className="Genres">{genres.map(genreMapper)}</ul>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.personalization.error,
        topGenres: state.personalization.topGenres
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTopGenres: range => dispatch(actions.getTopGenres(range))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Genres);