import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../shared/utilities";

const initialState = {
    token: null,
    user: {},
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SPOTIFY_SIGN_IN_SUCCESS:
            return updateObject(state, {
                token: action.token
            });
        case actionTypes.AUTH_SPOTIFY_SIGN_IN_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        case actionTypes.AUTH_SPOTIFY_GET_USER_SUCCESS:
            return updateObject(state, {
                user: action.user
            });
        case actionTypes.AUTH_SPOTIFY_GET_USER_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        case actionTypes.AUTH_SPOTIFY_SIGN_OUT:
            return updateObject(state, {
                token: null
            });
        default:
            return state;
    }
};

export default reducer;