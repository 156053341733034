import * as actionTypes from "./actionTypes";
import {getToken, signOut} from "../../requests/spotify/auth";
import {getCurrentUserProfile} from "../../requests/spotify/user";

const authSpotifySuccess = token => {
    return {
        type: actionTypes.AUTH_SPOTIFY_SIGN_IN_SUCCESS,
        token: token
    };
};

const authSpotifyFailed = errorMsg => {
    return {
        type: actionTypes.AUTH_SPOTIFY_SIGN_IN_FAILED,
        errorMsg: errorMsg
    };
};

const getUserSuccess = user => {
    return {
        type: actionTypes.AUTH_SPOTIFY_GET_USER_SUCCESS,
        user: user
    };
};

const getUserFailed = errorMsg => {
    return {
        type: actionTypes.AUTH_SPOTIFY_GET_USER_FAILED,
        errorMsg: errorMsg
    };
};

const signOutActionSpotify = () => {
    return {
        type: actionTypes.AUTH_SPOTIFY_SIGN_OUT
    }
};

export const signOutSpotify = () => {
    return dispatch => {
        signOut(() => dispatch(signOutActionSpotify()));
    }
};

export const authSpotify = () => {
    return dispatch => {
        let token = null;
        try {
            token = getToken();
        } catch (e) {
            dispatch(authSpotifyFailed(e.message));
        }
        if (token) {
            dispatch(authSpotifySuccess(token));
            // get user
            getCurrentUserProfile(token).then(res => {
                dispatch(getUserSuccess(res.data));
            }).catch(err => {
                if (err && err.message) dispatch(getUserFailed(err.message));
            })
        }
    };
};