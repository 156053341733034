import * as actionTypes from "./actionTypes";
import {getCurrentlyPlaying as getCurrentlyPlayingRequest} from "../../requests/spotify/player";

const getCurrentlyPlayingSuccess = currentlyPlaying => {
    return {
        type: actionTypes.PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_SUCCESS,
        currentlyPlaying: currentlyPlaying
    };
};

const getCurrentlyPlayingFailed = errorMsg => {
    return {
        type: actionTypes.PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_FAILED,
        errorMsg: errorMsg
    };
};

export const getCurrentlyPlaying = () => {
    return (dispatch, getState) => {
        getCurrentlyPlayingRequest(getState().auth.token).then(res => {
            dispatch(getCurrentlyPlayingSuccess(res.data));
        }).catch(err => {
            if (err && err.message) dispatch(getCurrentlyPlayingFailed(err.message));
        })
    }
};