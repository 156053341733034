import axios from "axios";

const API_BASE_URL = 'https://api.spotify.com/v1';

export default (token) => {
    return axios.create({
        baseURL: API_BASE_URL,
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
};