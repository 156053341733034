export const AUTH_SPOTIFY_SIGN_IN_SUCCESS = 'AUTH_SPOTIFY_SIGN_IN_SUCCESS';
export const AUTH_SPOTIFY_SIGN_IN_FAILED = 'AUTH_SPOTIFY_SIGN_IN_FAILED';
export const AUTH_SPOTIFY_GET_USER_SUCCESS = 'AUTH_SPOTIFY_GET_USER_SUCCESS';
export const AUTH_SPOTIFY_GET_USER_FAILED = 'AUTH_SPOTIFY_GET_USER_FAILED';
export const AUTH_SPOTIFY_SIGN_OUT = 'AUTH_SPOTIFY_SIGN_OUT';

export const PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_FAILED = 'PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_FAILED';
export const PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_FAILED = 'PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_FAILED';
export const PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_FAILED = 'PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_FAILED';
export const PERSONALIZATION_SPOTIFY_GET_TRACKS_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TRACKS_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TRACK_FEATURES_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TRACK_FEATURES_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TRACK_LYRICS_SUCCESS = 'PERSONALIZATION_SPOTIFY_GET_TRACK_LYRICS_SUCCESS';
export const PERSONALIZATION_SPOTIFY_GET_TRACK_META_FAILED = 'PERSONALIZATION_SPOTIFY_GET_TRACKS_FAILED';


export const PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_SUCCESS = 'PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_SUCCESS';
export const PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_FAILED = 'PLAYER_SPOTIFY_GET_CURRENTLY_PLAYING_FAILED';