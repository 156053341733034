import React, {Component} from 'react';
import './Tracks.css';
import albumArt from '../../assets/images/album-cover.jpg';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import MusicItem from "../../components/MusicItem/MusicItem";
import * as actions from "../../store/actions";
import {FUNCTIONAL_WORDS, SPOTIFY_PERSONALIZATION_RANGES} from "../../shared/constants";
import {connect} from "react-redux";
import WordCloud from "react-d3-cloud";

class Tracks extends Component {
    state = {
        options: SPOTIFY_PERSONALIZATION_RANGES.slice(0),
        cloudWrap: null,
        cloudWidth: 0
    };

    componentDidMount() {
        this.props.getTopTracks(this.state.options[0].range);
        this.props.getTrackMeta(this.state.options[0].range);

        window.addEventListener('resize', this.measureCloud);
        setTimeout(this.measureCloud, 0);
    }

    changeRangeHandler = (o, i) => {
        this.props.getTopTracks(o.range);
        this.props.getTrackMeta(o.range);
        this.setState(prev => {
            const updatedOptions = prev.options.slice();
            updatedOptions.splice(i + 1, 1);
            updatedOptions.unshift(o);
            return {
                options: updatedOptions
            }
        })
    };

    measureCloud = () => {
        if (this.state.cloudWrap) this.setState({
            cloudWidth: this.state.cloudWrap.clientWidth
        });
    };

    setCloudWrap = ref => {
        if (ref) this.setState({cloudWrap: ref});
    };

    processLyrics = () => {
        const lyricsMap = {},
            lyrics = this.props.trackLyrics
                .map(l => l.body).join(' ').toLowerCase()
                .split(/[^'\w]+/)
                .filter(v => v && FUNCTIONAL_WORDS.indexOf(v) === -1 && v.indexOf('\'') === -1);
        lyrics.forEach(l => {
            if (!lyricsMap[l]) lyricsMap[l] = 0;
            lyricsMap[l]++;
        });
        const topLyrics = Object.keys(lyricsMap)
            .sort((a, b) => lyricsMap[b] - lyricsMap[a])
            .map(word => ({
                value: lyricsMap[word],
                text: word
            }));
        return topLyrics.slice(0, 100);
    };

    processFeatures = () => {
        const total = this.props.trackFeatures.reduce((prev, cur, i) => Object.keys(prev).reduce((p, c) => ({
            ...p, [c]: cur[c] * (this.props.trackFeatures.length - i) + prev[c]
        }), {}), {
            danceability: 0,
            energy: 0,
            loudness: 0,
            speechiness: 0,
            acousticness: 0,
            instrumentalness: 0,
            liveness: 0,
            valence: 0,
            tempo: 0,
            duration_ms: 0
        });
        const trackFeatures = Object.keys(total).reduce((prev, cur) => ({
            ...prev,
            [cur]: total[cur] / ((this.props.trackFeatures.length + 1) * this.props.trackFeatures.length / 2)
        }), {});
        return Object.keys(trackFeatures).map((key, i) => <p key={i}>
            {key}: {trackFeatures[key].toFixed(2)}
        </p>)
    };

    processTopTracks = (topThree) => {
        const topTracks = this.props.topTracks.map((t, i) => ({...t, rank: i + 1}))
            || Array.apply(null, Array(15));
        const trackMapper = (value, index) => <MusicItem
            key={index}
            image={value && value.album && value.album.images ? value.album.images[0].url : albumArt}
            imageAlt={value && value.album ? value.album.name : 'Sample Album'}
            title={value ? '#' + value.rank + ' ' + value.name : 'Track'}
            subtitle={
                (value && value.artists ? value.artists.map(v => v.name).join(', ') : 'Artist') +
                ' - ' +
                (value && value.album ? value.album.name : 'Album')
            }
        />;
        return (topThree ? topTracks.slice(0, 3) : topTracks.slice(3)).map(trackMapper);
    };

    render() {
        return (
            <section id="Tracks">
                <div className="Tracks-Header">
                    <div className="h2 horizontal">Your</div>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret size="lg" color="success">{this.state.options[0].label}</DropdownToggle>
                        <DropdownMenu>
                            {
                                this.state.options.slice(1).map((o, i) => <DropdownItem
                                    key={o.range}
                                    onClick={() => this.changeRangeHandler(o, i)}
                                >{o.label}</DropdownItem>)
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="h2 vertical">Top Tracks</div>
                </div>
                <div className="Tracks-Body">
                    <div className="Tracks top-three">
                        {this.processTopTracks(true)}
                    </div>
                    <div className="Tracks two-columns">
                        {this.processTopTracks()}
                    </div>
                </div>
                <div className="Tracks-Features Tracks-Body my-3 p-3">
                    <div className="h5 Tracks-Subtitle">Features - {this.state.options[0].label} Top Tracks</div>
                    {this.processFeatures()}
                </div>
                <div className="Tracks-Lyrics Tracks-Body my-3 p-3">
                    <div className="h5 Tracks-Subtitle">Lyrics Word Cloud - {this.state.options[0].label} Top Tracks</div>
                    <div ref={this.setCloudWrap}>
                        {
                            this.state.cloudWidth ?
                                <WordCloud
                                    data={this.processLyrics()}
                                    width={this.state.cloudWidth}
                                    font="sans-serif"
                                    padding={5}
                                    fontSizeMapper={word => Math.log2(word.value) * 8}
                                    rotate={() => [0, 270][Math.round(Math.random())]}/> : null
                        }
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.personalization.error,
        topTracks: state.personalization.topTracks,
        tracks: state.personalization.tracks,
        trackFeatures: state.personalization.trackFeatures,
        trackLyrics: state.personalization.trackLyrics
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTopTracks: range => dispatch(actions.getTopTracks(range)),
        getTrackMeta: range => dispatch(actions.getTrackMeta(range))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);