import React, {Fragment} from 'react';
import './Navigation.css';
import {auth as authSpotify} from '../../requests/spotify/auth';
import {Button} from "reactstrap";
import * as Scroll from 'react-scroll';
import {DEFAULT_SCROLL_OPTIONS} from "../../shared/constants";

const Navigation = (props) => {
    return (
        <nav className="Navigation">
            <ul>
                {
                    props.authenticated ? (<Fragment>
                        <li><Scroll.Link
                            to="Tracks" {...DEFAULT_SCROLL_OPTIONS} href="#Tracks">Top Tracks</Scroll.Link></li>
                        <li><Scroll.Link
                            to="Artists" {...DEFAULT_SCROLL_OPTIONS} href="#Artists">Top Artists</Scroll.Link></li>
                        <li><Scroll.Link
                            to="Genres" {...DEFAULT_SCROLL_OPTIONS} href="#Genres">Top Genres</Scroll.Link></li>
                    </Fragment>) : (<Fragment>
                        <li><Button color="success" block onClick={authSpotify} style={{
                            marginBottom: '1px'
                        }}>Connect with Spotify</Button></li>
                        <li><Button color="danger" block onClick={() => {}} disabled>Connect with Last.fm</Button></li>
                    </Fragment>)
                }
            </ul>
        </nav>
    );
};

export default Navigation;