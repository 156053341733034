import React from 'react';
import './ProfileBox.css';
import placeholder from "../../assets/images/placeholder.png";
import {FA} from "../../shared/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "reactstrap";

const ProfileBox = (props) => {
    const name = props.user.display_name || 'Visitor';
    const avatar = props.user.images && props.user.images.length ? props.user.images[0].url : placeholder;
    return (
        <div className="ProfileBox">
            <div className="ProfileBox-Welcome">Hello, {name}</div>
            {
                props.authenticated ? (
                    <Button
                        color="info"
                        size="sm"
                        className="ProfileBox-SignOut"
                        onClick={props.signout}>
                        <FontAwesomeIcon icon={FA.signout} fixedWidth/> Sign Out
                    </Button>
                ) : null
            }
            <div className="ProfileBox-Avatar" style={{
                backgroundImage: 'url(\'' + avatar + '\')'
            }}><span className="sr-only">Profile Picture</span></div>
        </div>
    );
};

export default ProfileBox;