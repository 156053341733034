import React from 'react';
import './MusicItem.css';

const MusicItem = (props) => {
    return (
        <div className="MusicItem">
            <div className="MusicItem-Image" style={{
                backgroundImage: 'url(\'' + props.image + '\')'
            }}><span className="sr-only">{props.imageAlt}</span></div>
            <div className="MusicItem-Description">
                <div className="MusicItem-Description-Title">{props.title}</div>
                <div className="MusicItem-Description-Subtitle">{props.subtitle}</div>
            </div>
        </div>
    );
};

export default MusicItem;