import * as actionTypes from "./actionTypes";
import {getTopTracks as getTopTracksFromSpotify} from "../../requests/spotify/personalization";
import {getTopArtists as getTopArtistsFromSpotify} from "../../requests/spotify/personalization";
import {getTrackFeatures as getTrackFeaturesFromSpotify} from "../../requests/spotify/tracks";
import {reflect} from "../../shared/utilities";
import {getTracksLyrics} from "../../requests/lyrics";

const getTopTracksSuccess = topTracks => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_SUCCESS,
        topTracks: topTracks
    };
};

const getTopTracksFailed = errorMsg => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_FAILED,
        errorMsg: errorMsg
    };
};

export const getTopTracks = (range) => {
    return (dispatch, getState) => {
        getTopTracksFromSpotify({
            range: range,
            token: getState().auth.token
        }).then(res => {
            dispatch(getTopTracksSuccess(res.data.items));
        }).catch(err => {
            if (err && err.message) dispatch(getTopTracksFailed(err.message));
        })
    }
};

const getTopArtistsSuccess = topArtists => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_SUCCESS,
        topArtists: topArtists
    };
};

const getTopArtistsFailed = errorMsg => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_FAILED,
        errorMsg: errorMsg
    };
};

export const getTopArtists = (range) => {
    return (dispatch, getState) => {
        getTopArtistsFromSpotify({
            range: range,
            token: getState().auth.token
        }).then(res => {
            dispatch(getTopArtistsSuccess(res.data.items));
        }).catch(err => {
            if (err && err.message) dispatch(getTopArtistsFailed(err.message));
        })
    }
};

const getTopGenresSuccess = topGenres => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_SUCCESS,
        topGenres: topGenres
    };
};

const getTopGenresFailed = errorMsg => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_FAILED,
        errorMsg: errorMsg
    };
};

export const getTopGenres = (range) => {
    return (dispatch, getState) => {
        getTopArtistsFromSpotify({
            range: range,
            token: getState().auth.token,
            limit: 50
        }).then(res => {
            dispatch(getTopGenresSuccess(res.data.items));
        }).catch(err => {
            if (err && err.message) dispatch(getTopGenresFailed(err.message));
        })
    }
};

const getTracksSuccess = tracks => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACKS_SUCCESS,
        tracks: tracks
    };
};

const getTrackFeaturesSuccess = trackFeatures => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_FEATURES_SUCCESS,
        trackFeatures: trackFeatures
    };
};

const getTrackLyricsSuccess = trackLyrics => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_LYRICS_SUCCESS,
        trackLyrics: trackLyrics
    };
};

const getTrackMetaFailed = errorMsg => {
    return {
        type: actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_META_FAILED,
        errorMsg: errorMsg
    };
};

export const getTrackMeta = (range) => {
    return (dispatch, getState) => {
        getTopTracksFromSpotify({
            range: range,
            token: getState().auth.token,
            limit: 50
        }).then(res => {
            dispatch(getTracksSuccess(res.data.items));
            return Promise.all([
                reflect(getTrackFeaturesFromSpotify({
                    token: getState().auth.token,
                    trackIds: res.data.items.map(v => v.id)
                }), 'features', 1),
                reflect(getTracksLyrics(res.data.items.map(v => v.external_ids.isrc)), 'lyrics', 2)
            ]);
        }).then(res => {
            res.forEach(r => {
                if (r.type === 'features') dispatch(getTrackFeaturesSuccess(r.response.data.audio_features));
                else dispatch(getTrackLyricsSuccess(r.response))
            })
        }).catch(err => {
            if (err && err.message) dispatch(getTrackMetaFailed(err.message));
        })
    }
};