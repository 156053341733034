import axios from "./index";
import {buildPath} from "../../shared/utilities";

export const getTrackFeatures = function ({token, trackIds}) {
    return axios(token).get(
        buildPath('audio-features'), {
            params: {
                ids: trackIds.join(',')
            }
        }
    );
};