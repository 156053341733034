import * as jsonp from "jsonp";

export function getUrlParams() {
    const params = {};
    window.location.href.replace(/[?&#]+([^=&]+)=([^&]*)/gi, function (match, key, value) {
        params[key] = value;
    });
    return params;
}

export function getUrlParam(name, defaultValue) {
    return (window.params || (window.params = getUrlParams()))[name] || defaultValue;
}

export function getRandomString(length) {
    const pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.apply(null, Array(length)).map(() => pool.charAt(Math.floor(Math.random() * pool.length))).join('');
}

export const updateObject = (oldObj, newProps) => {
    return {
        ...oldObj,
        ...newProps
    };
};

export const buildPath = (...path) => {
    return path.join('/');
};

export const buildQueryString = (params) => {
    return encodeURI(Object.keys(params).map(v => [v, params[v]].join('=')).join('&'));
};

export const getJsonp = (url, config) => {
    return new Promise(function (resolve, reject) {
        jsonp(url, config, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        })
    });
};

export const reflect = (p, type, id) => p.then(
    response => ({
        response: response,
        status: 'resolved',
        type: type,
        id: id
    }),
    error => ({
        error: error,
        status: 'rejected',
        type: type,
        id: id
    })
);
