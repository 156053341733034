import React, {Component} from 'react';
import './NowPlaying.css';
import placeholder from '../../assets/images/album-cover.jpg';
import * as actions from "../../store/actions";
import {connect} from "react-redux";

class NowPlaying extends Component {
    componentDidMount() {
        this.props.getCurrentlyPlaying();
        setInterval(this.props.getCurrentlyPlaying, 5000);
    }

    render() {
        let item, cover, albumName, progress, progressPercentage, artistName, itemName;

        if (this.props.currentlyPlaying) {
            item = this.props.currentlyPlaying.item;
            cover = item.album && item.album.images ? item.album.images[0].url : placeholder;
            albumName = item.album && item.album.name;
            progress = this.props.currentlyPlaying.progress_ms + 'ms of ' + item.duration_ms + 'ms';
            progressPercentage = this.props.currentlyPlaying.progress_ms / item.duration_ms * 100;
            artistName = item.artists.map(v => v.name).join(', ');
            itemName = item.name;
        }

        return (
            <div className="NowPlaying">
                <div className="NowPlaying-Cover" style={{
                    backgroundImage: 'url(\'' + (item ? cover : placeholder) + '\')'
                }}><span className="sr-only">{item ? albumName : 'Placeholder Album Cover'}</span></div>
                <div className="NowPlaying-Meta">
                    {
                        item ? (
                            <div className="NowPlaying-Progress text-muted">
                                <div className="line">
                                    <div className="dot" style={{
                                        left: 'calc(' + progressPercentage + '% - 5px)'
                                    }}><span className="sr-only">{progress}</span></div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className="NowPlaying-Info">
                        <div className="h5">{item ? itemName : 'NOW PLAYING - [Silence]'}</div>
                        {
                            item ? (
                                <div className="text-muted">{artistName} - {albumName}</div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentlyPlaying: state.player.currentlyPlaying
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentlyPlaying: () => dispatch(actions.getCurrentlyPlaying())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NowPlaying);