import React, {Component} from 'react';
import './Artists.css';
import placeholder from '../../assets/images/placeholder.png';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import MusicItem from "../../components/MusicItem/MusicItem";
import * as actions from "../../store/actions";
import {SPOTIFY_PERSONALIZATION_RANGES} from "../../shared/constants";
import {connect} from "react-redux";

class Artists extends Component {
    state = {
        options: SPOTIFY_PERSONALIZATION_RANGES.slice(0)
    };

    componentDidMount() {
        this.props.getTopArtists(this.state.options[0].range);
    }

    changeRangeHandler = (o, i) => {
        this.props.getTopArtists(o.range);
        this.setState(prev => {
            const updatedOptions = prev.options.slice();
            updatedOptions.splice(i + 1, 1);
            updatedOptions.unshift(o);
            return {
                options: updatedOptions
            }
        })
    };

    render() {
        const topArtists = this.props.topArtists.slice(0, 15).map((t, i) => ({...t, rank: i + 1})) || Array.apply(null, Array(15));
        const artistMapper = (value, index) => <MusicItem
            key={index}
            image={value && value.images ? value.images[0].url : placeholder}
            imageAlt={value ? value.name : 'Artist'}
            title={value ? '#' + value.rank + ' ' + value.name : 'Artist'}
            subtitle={
                'Followers: ' + (value ? value.followers.total : '0') +
                ' | ' +
                'Popularity: ' + (value ? value.popularity : '0')
            }
        />;
        return (
            <section id="Artists">
                <div className="Artists-Header">
                    <div className="h2 horizontal">Your</div>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret size="lg" color="success">{this.state.options[0].label}</DropdownToggle>
                        <DropdownMenu>
                            {
                                this.state.options.slice(1).map((o, i) => <DropdownItem
                                    key={o.range}
                                    onClick={() => this.changeRangeHandler(o, i)}
                                >{o.label}</DropdownItem>)
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <div className="h2 vertical">Top Artists</div>
                </div>
                <div className="Artists-Body">
                    <div className="Artists top-three">
                        {topArtists.slice(0, 3).map(artistMapper)}
                    </div>
                    <div className="Artists two-columns">
                        {topArtists.slice(3).map(artistMapper)}
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.personalization.error,
        topArtists: state.personalization.topArtists
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTopArtists: range => dispatch(actions.getTopArtists(range))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Artists);