import React, {Component, Fragment} from 'react';
import './App.css';
import Layout from "./hoc/Layout/Layout";
import {connect} from "react-redux";
import * as actions from "./store/actions";
import Tracks from "./containers/Tracks/Tracks";
import backgroundArt from './assets/images/bg-cards-music.jpg';
import {Card, CardBody, CardText, CardTitle} from "reactstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import {FA_ICONS} from "./shared/constants";
import Artists from "./containers/Artists/Artists";
import Genres from "./containers/Genres/Genres";

// Import icons from font-awesome
library.add(...FA_ICONS);

class App extends Component {
    componentDidMount() {
        this.props.authSpotify(); // not async
    }

    render() {
        const authenticated = !!this.props.token;
        return (
            <div className="App">
                <Layout
                    authenticated={authenticated}
                    signout={this.props.signOutSpotify}
                    user={this.props.user}>
                    {
                        authenticated ? (<Fragment>
                            <Tracks/>
                            <Artists/>
                            <Genres/>
                        </Fragment>) : (
                            <section>
                                <Card className="App-Intro">
                                    <div className="App-Intro-Background" style={{
                                        backgroundImage: 'url(\'' + backgroundArt + '\')'
                                    }}><span className="sr-only">Background Art</span></div>
                                    <CardBody className="App-Intro-Text">
                                        <CardTitle>Visualize Your Music Library</CardTitle>
                                        <CardText>Connect to your Spotify/Last.fm account, and visualize your music data in a various of forms.</CardText>
                                    </CardBody>
                                </Card>
                            </section>
                        )
                    }
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        user: state.auth.user,
        token: state.auth.token
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authSpotify: () => dispatch(actions.authSpotify()),
        signOutSpotify: () => dispatch(actions.signOutSpotify())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
