import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../shared/utilities";

const initialState = {
    topTracks: [],
    topArtists: [],
    topGenres: [],
    tracks: [],
    trackFeatures: [],
    trackLyrics: [],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_SUCCESS:
            return updateObject(state, {
                topTracks: action.topTracks
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_TRACKS_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_SUCCESS:
            return updateObject(state, {
                topArtists: action.topArtists
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_ARTISTS_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_SUCCESS:
            return updateObject(state, {
                topGenres: action.topGenres
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TOP_GENRES_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACKS_SUCCESS:
            return updateObject(state, {
                tracks: action.tracks
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_FEATURES_SUCCESS:
            return updateObject(state, {
                trackFeatures: action.trackFeatures
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_LYRICS_SUCCESS:
            return updateObject(state, {
                trackLyrics: action.trackLyrics
            });
        case actionTypes.PERSONALIZATION_SPOTIFY_GET_TRACK_META_FAILED:
            return updateObject(state, {
                error: action.errorMsg
            });
        default:
            return state;
    }
};

export default reducer;