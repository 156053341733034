import {LYRICS_API_KEY} from "../../shared/constants";
import {buildQueryString, getJsonp, reflect} from "../../shared/utilities";

const API_BASE_URL = 'https://api.musixmatch.com/ws/1.1';

export const getTrackLyric = function (isrc) {
    return getJsonp(API_BASE_URL + '/track.get?' + buildQueryString({
        format: 'jsonp',
        apikey: LYRICS_API_KEY,
        track_isrc: isrc
    }), {
        name: 'callback_' + isrc,
        timeout: 1000
    }).then(res => {
        if (res.message.header.status_code === 200) {
            const id = res.message.body.track.track_id;
            return getJsonp(API_BASE_URL + '/track.lyrics.get?' + buildQueryString({
                apikey: LYRICS_API_KEY,
                track_id: id,
                format: 'jsonp'
            }), {
                name: 'callback_' + id,
                timeout: 1000
            });
        } else return Promise.resolve(null);
    });
};

export const getTracksLyrics = function (isrcArr) {
    return Promise.all(isrcArr.map((isrc, i) => reflect(getTrackLyric(isrc), 'track_lyric', isrc, i + 1)))
        .then(res => {
            const ret = res.map(r => {
                if (r.status !== 'resolved' || !r.response || r.response.message.header.status_code !== 200) return null;
                else {
                    const lb = r.response.message.body.lyrics.lyrics_body;
                    return {
                        rank: r.id,
                        body: lb.substring(0, lb.indexOf('***'))
                    };
                }
            }).filter(r => r);
            return Promise.resolve(ret);
        });
};