import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {Provider} from "react-redux";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from './store/reducers/auth';
import personalizationReducer from './store/reducers/personalization';
import playerReducer from './store/reducers/player';
import thunk from "redux-thunk";

const isProd = false;
const reducer = combineReducers({
    auth: authReducer,
    personalization: personalizationReducer,
    player: playerReducer
});
const enhancer = (isProd ? false : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const store = createStore(reducer, enhancer(applyMiddleware(thunk)));

ReactDOM.render((
    <Provider store={store}><App /></Provider>
), document.getElementById('root'));
registerServiceWorker();
